<template>
    <div class="page">
        <div class="header">
            <div class="btns">
                <!--                <el-button size="mini" @click="onUpload" icon="el-icon-download" type="defalut">导出</el-button>-->
                <!--                <el-button size="mini" @click="onUpload" icon="el-icon-upload" type="defalut">导入</el-button>-->
                <el-button size="mini" @click="onAdd" icon="el-icon-plus" type="defalut" v-has="'custom_add'">新建</el-button>
                <el-button size="mini" @click="getData" icon="el-icon-refresh">刷新</el-button>
            </div>
            <el-page-header @back="$router.go(-1)" content="自定义问卷" />
        </div>
        <div class="search">
            <el-form :inline="true" :model="search" size="small">
                <el-form-item label="名称">
                    <el-input v-model="search.questionTitle" placeholder="请输入" />
                </el-form-item>
                <el-form-item label="时间">
                    <el-date-picker v-model="search.daterange" type="daterange" size="small" range-separator="至"
                                    @change="getData" start-placeholder="开始日期" value-format="yyyy-MM-dd" end-placeholder="结束日期">
                    </el-date-picker>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" plain @click="getData" v-has="'custom_search'">查询</el-button>
                </el-form-item>
                <el-button type="primary" plain @click="getInfo" style="float: right"  size="small" v-has="'custom_search'">问卷详情</el-button>
            </el-form>
        </div>
        <div class="main-content">
            <div class="table-content">
                <el-table :data="table.data" border stripe size="mini" v-loading="loading" width="100%" height="100%"
                          :header-cell-style="{ backgroundColor: '#FAFAFA' }">
                    <el-table-column type="index" label="序号" align="center"
                                     :index="(index) => { return (this.table.page - 1) * this.table.pageSize + index + 1 }" width="55" />
                    <el-table-column prop="questionTitle" label="题目" width=""/>
                    <el-table-column prop="" label="选项" width="">
                        <template slot-scope="scope">
                            <el-popover
                                    placement="right"
                                    width="400"
                                    trigger="click">
                                <el-tag v-for="(items, index) in scope.row.answerOption" :key="index" style="margin: 10px">{{items.AnswerTxt}}</el-tag>
                                <el-button slot="reference" size="small">{{scope.row.answerOption.length}}项</el-button>
                            </el-popover>

                        </template>
                    </el-table-column>
                    <el-table-column prop="titleExplain" label="题目解释" width=""/>
                    <el-table-column prop="" label="类型" width="">
                        <template slot-scope="scope">
                            <el-tag v-if="scope.row.titleType=='1'">单选</el-tag>
                            <el-tag v-else>多选</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column prop="create_time" label="创建时间" width=""/>
                    <el-table-column align="center" width="200" label="操作" fixed="right">
                        <template slot-scope="scope">
                            <el-button type="warning" plain size="mini" @click="handleUpdate(scope.row)" icon="el-icon-edit" v-has="'custom_edit'">
                                修 改
                            </el-button>
                            <el-button type="danger"  plain size="mini" @click="handleDelete(scope.row)" icon="el-icon-edit" v-has="'custom_del'">
                                删 除
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
        <div class="pager" v-if="table.total > table.pageSize">
            <el-pagination layout="total, prev, pager, next, sizes" :page-sizes="[10, 20, 30, 50, 100, 200, 500, 1000]"
                           :current-page="table.page" :total="table.total" :page-size="table.pageSize"
                           @current-change="handlePageChange" @size-change="handlePageSizeChange" />
        </div>
        <data-form :visible="dialogVisible" :form="form" @close="dialogVisible = false" @update="getData"
                   ref="vmodel"></data-form>
        <view-info :data="table.data" :visible="infoModel" @close="infoModel=false"
                   @update="getData"></view-info>
    </div>
</template>

<script>
    import viewInfo from './view'
    import dataForm from './widgets/dataform'
    export default {
        components: {
            viewInfo,
            dataForm
        },
        data() {
            return {
                loading: false,
                dialogVisible: false,
                infoModel: false,
                search: {},
                table: {
                    data: [],
                    total: 0,
                    page: 1,
                    pageSize: 100
                },
                form:{
                    answerOption:[
                        {
                            AnswerOption: '',
                            AnswerTxt: ""
                        }
                    ]
                }
            }
        },
        methods: {
            getInfo(){
                this.infoModel = true
            },
            onAdd(){
                this.form = {
                    type:2,
                    answerOption:[
                        {
                            AnswerOption: "",
                            AnswerTxt: ""
                        }
                    ]
                }
                this.dialogVisible = true
            },
            handlePageChange(e) {
                this.table.page = e
                this.getData()
            },
            handlePageSizeChange(e) {
                this.table.pageSize = e
                this.getData()
            },
            handleUpdate(row) {
                this.form = row
                this.dialogVisible = true
            },
            handleDelete(row) {
                this.$confirm(`确认要删除病案号 ${row.questionTitle} 的记录吗？`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.loading = true
                    this.$http.delete(`/wenjuan/${row.id}`)
                        .then(resp => {
                            this.$message[resp.data.status](resp.data.msg)
                            if (resp.data.status == 'success') this.getData()
                        }).catch(err => {
                        this.$message.error(err.message)
                    }).finally(() => {
                        this.loading = false
                    })
                }).catch(() => {
                });
            },
            async getData() {
                this.loading = true
                const resp = await this.$http.get(`/wenjuan?type=2&page=${this.table.page}&pageSize=${this.table.pageSize}`, { params: this.search })
                if (!resp.data) {
                    this.$message.error(resp.data.msg)
                    this.dialogVisible = false
                    this.loading = false
                } else {
                    this.dialogVisible = false
                    this.loading = false
                    this.table.data = resp.data.data
                    this.table.total = resp.data.total
                }
            }
        },
        mounted() {
            this.getData()
        }
    }
</script>

<style lang="scss" scoped>
    .page {
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    .header {
        padding-bottom: 10px;

        .btns {
            float: right;
        }
    }

    .search {
        padding: 10px;
        background-color: #FAFAFA;
        border-top: 1px solid #EBEEF5;
        border-left: 1px solid #EBEEF5;
        border-right: 1px solid #EBEEF5;

        ::v-deep .el-form-item {
            padding: 0 !important;
            margin-bottom: 0 !important;
        }
    }

    .main-content {
        flex: 1;
        position: relative;
    }

    .table-content {
        position: absolute;
        width: 100%;
        height: 100%;
    }

    .pager {
        padding-top: 10px;
        text-align: center;
    }
</style>
